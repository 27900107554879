import { useForm } from '@mantine/form'
import { IconLogin } from '@tabler/icons-react'
import { useMutation } from '@tanstack/react-query'
import { Link, useNavigate } from 'react-router-dom'
import { notifications } from '@mantine/notifications'
import { zodResolver } from 'mantine-form-zod-resolver'
import { Text, Title, Paper, Button, Center, Divider, Box } from '@mantine/core'

import DataRepo from '@api/datasource/data'
import queryClient from '@api/datasource/query'

import { useStoreBase } from '@store/index'

import { SignUpSchema, SignUpType, UserType } from '@customTypes/user'

import { useIsMobile } from '@hooks/mobile'

import { ErrorService } from '@utils/error'
import { isLoadingMutation } from '@utils/network'

import { UI } from '@constants/app'
import { RoutesApp } from '@constants/routes'

import Input from '@components/shared/input'

export default function Login() {
  const navigate = useNavigate()

  const isMobile = useIsMobile()

  const { setUser, reset } = useStoreBase()

  const form = useForm<SignUpType>({
    initialValues: {
      email: '',
      displayName: '',
      password: '',
      confirmPassword: '',
    },
    validate: zodResolver(SignUpSchema),
  })

  const signUpMutation = useMutation<UserType, ErrorService, SignUpType>({
    mutationFn: async (params) => {
      const response = await DataRepo.signUpWithEmailAndPassword(params)
      queryClient.clear()
      reset()
      return response
    },
    onSettled: (userData, error) => {
      if (error) {
        return notifications.show({
          color: 'red',
          title: 'Error',
          message: error.message || 'Error desconocido',
        })
      }
      if (userData) {
        setUser(userData)
        navigate(RoutesApp.HOME)
      } else {
        notifications.show({
          color: 'red',
          title: 'Error',
          message: 'No se obtuvo usuario',
        })
      }
    },
  })

  const Container = isMobile ? Box : Paper

  return (
    <Center className="cd-w-full cd-h-full">
      <Container
        withBorder
        className="cd-min-w-full cd-p-[1.25rem] md:cd-min-w-[450px] md:cd-p-[2rem]"
        radius="md"
        shadow="xs"
      >
        <form onSubmit={form.onSubmit((values) => signUpMutation.mutate(values))}>
          <div className="cd-flex cd-flex-col cd-gap-[2rem]">
            <Title className="cd-title-form" order={2}>
              Sein
              <Text inherit c="blue" component="span">
                pro
              </Text>
            </Title>

            <div className="cd-flex cd-flex-col cd-gap-[1rem]">
              <Input
                capitalize={false}
                label="Email"
                placeholder="email@seinpro.ec"
                type="email"
                typeInput="text"
                {...form.getInputProps('email')}
                onBlur={() => form.validateField('email')}
                onChange={(e) =>
                  form.setFieldValue('email', e.target.value.toLowerCase().trim(), {
                    forceUpdate: true,
                  })
                }
              />

              <Input
                capitalize
                label="Nombre"
                placeholder="Ingrese su nombre"
                typeInput="text"
                {...form.getInputProps('displayName')}
                onBlur={() => form.validateField('displayName')}
              />

              <Input
                capitalize={false}
                label="Contraseña"
                placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;"
                type="password"
                typeInput="text"
                {...form.getInputProps('password')}
                onBlur={() => form.validateField('password')}
              />

              <Input
                capitalize={false}
                label="Confirmar contraseña"
                placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;"
                type="password"
                typeInput="text"
                {...form.getInputProps('confirmPassword')}
                onBlur={() => form.validateField('confirmPassword')}
              />

              <Button
                fullWidth
                className="cd-mt-[0.5rem]"
                leftSection={<IconLogin />}
                loaderProps={{ type: 'dots' }}
                loading={isLoadingMutation(signUpMutation)}
                size={UI.Size}
                type="submit"
                variant="filled"
              >
                Registrarse
              </Button>
            </div>

            <Divider label="o" />

            <Link to={RoutesApp.LOGIN}>
              <Text className="cd-text-sm cd-text-center">
                Ya tienes cuenta?{' '}
                <Text inherit c="blue" component="span">
                  Inicia sesión
                </Text>
              </Text>
            </Link>
          </div>
        </form>
      </Container>
    </Center>
  )
}
