import { DATE_FORMAT } from '@constants/app'
import { RepeatReminder } from '@constants/client'
import { GetClientsType, ClientBaseType } from '@customTypes/client'
import { EventBaseType, SearchEventType } from '@customTypes/event'
import moment from 'moment'

type TotalParams = {
  value: number
  quantity: number
  percentage: number
  hasAccessory: boolean
}

export const getTotal = (params: TotalParams) => {
  const { value, quantity, percentage, hasAccessory: hasAccessory } = params

  let result = value * quantity

  if (hasAccessory) {
    result *= 1 + percentage / 100
  }

  return Number(result.toFixed(2))
}

type SearchParams<T> = {
  params: GetClientsType
  data?: T[]
}

export const onFilterClient = <T extends ClientBaseType>(params: SearchParams<T>) => {
  const {
    params: { field, query },
    data,
  } = params

  if (!query || !field || !data) {
    return data
  }

  if (field === 'uid') {
    return data.filter((item) => item.uid.startsWith(query))
  }

  if (['name', 'ruc', 'email', 'phone'].includes(field)) {
    const keyField = field as 'name' | 'ruc' | 'email' | 'phone'
    return data.filter((item) => {
      const value = item[keyField]
      if (value) {
        return value.toLowerCase().includes(query.toLowerCase())
      }
      return false
    })
  }

  return data
}

export const getRemainderText = (remainder: ClientBaseType['remainder']) => {
  if (!remainder) {
    return 'Sin recordatorio'
  }

  const { repeat, date } = remainder

  const dateFormatted = moment.unix(date).format(DATE_FORMAT)

  if (repeat === RepeatReminder.never.value) {
    return dateFormatted
  }

  return `${dateFormatted} (${RepeatReminder[repeat].label})`
}

type SearchEventsParams<T> = {
  params: SearchEventType
  data?: T[]
}

export const onFilterEvents = <T extends EventBaseType>(params: SearchEventsParams<T>) => {
  const {
    params: { field, query },
    data = [],
  } = params

  if (field === 'date') {
    const { startDate, endDate } = params.params

    if (startDate && endDate) {
      return data.filter((item) => item.date >= startDate && item.date <= endDate)
    }

    if (startDate) {
      return data.filter((item) => item.date >= startDate)
    }

    if (endDate) {
      return data.filter((item) => item.date <= endDate)
    }
  }

  if (!query || !field) {
    return data
  }

  if (['name', 'description'].includes(field)) {
    const keyField = field as 'name' | 'description'
    return data.filter((item) => {
      const value = item[keyField]
      if (value) {
        return value.toLowerCase().includes(query.toLowerCase())
      }
      return false
    })
  }

  return data
}
