import React from 'react'
import { useForm } from '@mantine/form'
import { Button, Fieldset, Modal, Text } from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { zodResolver } from 'mantine-form-zod-resolver'
import { useMutation, useQuery } from '@tanstack/react-query'

import {
  RawMaterialBaseType,
  CreateRawMaterialBaseType,
  UpdateRawMaterialBaseType,
  CreateRawMaterialBaseSchema,
} from '@customTypes/rawMaterial'
import { ModalProps } from '@customTypes/modal'

import DataRepo from '@api/datasource/data'
import queryClient from '@api/datasource/query'

import QueryKeys from '@constants/queryKeys'
import { MAX_ATTACHMENTS, MeasuredUnit } from '@constants/rawMaterial'

import { useIsMobile } from '@hooks/mobile'

import { ErrorService } from '@utils/error'
import { isLoadingMutation, isLoadingOrRefetchQuery } from '@utils/network'
import { buildSelectOptions, filterSelect } from '@utils/form'

import Input from '@components/shared/input'
import { Dropzone } from '@components/shared/dropzone'
import ConfirmationModal from '@components/shared/confirmationModal'
import { filterRawMaterialsAttachments } from '@utils/rawMaterial'

const INITIAL: CreateRawMaterialBaseType['rawMateria'] = {
  name: '',
  description: '',
  value: 0,
  measure: 'kg',
  alertStock: 0,
  attachments: [],
}

const RawMaterialForm = (props: ModalProps<RawMaterialBaseType>) => {
  const { open, modal, data, size = 'lg', onSubmitted, onClose: outerOnClose } = props

  const isMobile = useIsMobile()

  const [confirmExit, setConfirmExit] = React.useState(false)

  const [selectedReuseRM, setSelectedReuseRM] = React.useState<RawMaterialBaseType | null>(null)

  const [rawMaterialsNames, setRawMaterialsNames] = React.useState<string[]>([])

  const formRawMaterial = useForm<CreateRawMaterialBaseType['rawMateria']>({
    initialValues: INITIAL,
    validate: zodResolver(CreateRawMaterialBaseSchema.shape.rawMateria),
  })

  const rawMaterialsQuery = useQuery<RawMaterialBaseType[], ErrorService>({
    initialData: [],
    queryKey: [QueryKeys.GET_MATERIALS_KEY],
    queryFn: async () => {
      return await DataRepo.getRawMaterials({
        orderDir: 'asc',
      })
    },
  })

  const rawMaterialsAttachmentsQuery = useQuery<
    RawMaterialBaseType[],
    ErrorService,
    RawMaterialBaseType[],
    [string, RawMaterialBaseType[]]
  >({
    initialData: [],
    enabled: Boolean(rawMaterialsQuery.data?.length),
    queryKey: [QueryKeys.GET_MATERIALS_ATTACHMENTS_KEY, rawMaterialsQuery.data],
    queryFn: async ({ queryKey }) => {
      const rawMaterials = queryKey[1]

      const filteredRawMaterials = filterRawMaterialsAttachments(rawMaterials)

      return filteredRawMaterials
    },
  })

  const materialCreateMutation = useMutation<
    RawMaterialBaseType,
    ErrorService,
    CreateRawMaterialBaseType
  >({
    mutationFn: async (data) => {
      const response = await DataRepo.createRawMaterial(data)

      await queryClient.invalidateQueries({
        predicate: (query) => [QueryKeys.GET_MATERIALS_KEY].includes(query.queryKey[0] as string),
        refetchType: 'all',
      })

      onSubmitted?.(response)

      return response
    },
    onSettled: (_, error) => {
      if (error) {
        return notifications.show({
          color: 'red',
          title: 'Error',
          message: error.message ?? 'Error al crear la materia prima',
        })
      }

      notifications.show({
        color: 'green',
        title: 'Éxito',
        message: 'Materia prima creada correctamente',
      })

      onClose()
    },
  })

  const materialUpdateMutation = useMutation<void, ErrorService, UpdateRawMaterialBaseType>({
    mutationFn: async (data) => {
      const response = await DataRepo.updateRawMaterial(data)

      await queryClient.invalidateQueries({
        predicate: (query) => [QueryKeys.GET_MATERIALS_KEY].includes(query.queryKey[0] as string),
        refetchType: 'all',
      })

      onSubmitted?.()

      return response
    },
    onSettled: (_, error) => {
      if (error) {
        return notifications.show({
          color: 'red',
          title: 'Error',
          message: error.message ?? 'Error al actualizar la materia prima',
        })
      }

      notifications.show({
        color: 'green',
        title: 'Éxito',
        message: 'Materia prima actualizada correctamente',
      })

      onClose?.()
    },
  })

  React.useEffect(() => {
    if (open && data) {
      formRawMaterial.setValues(data)
    } else if (!open) {
      setSelectedReuseRM(null)
      formRawMaterial.setValues(INITIAL)
    }
    formRawMaterial.resetDirty()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, data])

  React.useEffect(() => {
    if (!rawMaterialsQuery.data) return

    setRawMaterialsNames(rawMaterialsQuery.data.map((item) => item.name))
  }, [rawMaterialsQuery.data])

  const isLoading = isLoadingMutation(materialCreateMutation, materialUpdateMutation)

  const isLoadingAttachments = isLoadingOrRefetchQuery(
    rawMaterialsAttachmentsQuery,
    rawMaterialsQuery,
  )

  const content = (
    <form
      onSubmit={formRawMaterial.onSubmit((values) => {
        if (data) {
          materialUpdateMutation.mutate({
            rawMateria: {
              ...values,
              uid: data.uid,
            } as UpdateRawMaterialBaseType['rawMateria'],
          })
        } else {
          materialCreateMutation.mutate({
            rawMateria: values as CreateRawMaterialBaseType['rawMateria'],
          })
        }
      })}
    >
      <div className="cd-flex cd-flex-col cd-gap-y-[1rem]">
        <Input
          label="Nombre"
          placeholder="Nombre"
          typeInput="text"
          {...formRawMaterial.getInputProps('name')}
          onBlur={() => {
            const isDuplicated = rawMaterialsNames.includes(formRawMaterial.values.name)

            if (isDuplicated) {
              formRawMaterial.setFieldError('name', 'El nombre de la materia prima ya existe')
              return
            }

            formRawMaterial.validateField('name')
          }}
          onChange={(e) => {
            formRawMaterial.clearFieldError('name')
            formRawMaterial.setFieldValue('name', e.target.value, { forceUpdate: true })
          }}
        />
        <Input
          label="Descripción"
          placeholder="Descripción"
          typeInput="text"
          {...formRawMaterial.getInputProps('description')}
          onBlur={() => formRawMaterial.validateField('description')}
        />

        <div className="cd-flex cd-flex-col cd-gap-y-[1rem] md:cd-flex-row md:cd-gap-x-[1rem]">
          <Input
            searchable
            allowDeselect={false}
            className="cd-basis-[100%] md:cd-basis-1/2"
            data={buildSelectOptions({
              data: Object.values(MeasuredUnit),
              value: 'value',
              label: 'label',
              groupBy: 'group',
            })}
            filter={(input) =>
              filterSelect({
                search: input.search,
                options: input.options,
              })
            }
            label="Medida"
            nothingFoundMessage="No se encontraron resultados"
            placeholder="Medida"
            typeInput="select"
            {...formRawMaterial.getInputProps('measure')}
            onBlur={() => formRawMaterial.validateField('measure')}
          />
          <Input
            allowDecimal
            allowNegative={false}
            clampBehavior="strict"
            className="cd-basis-[100%] md:cd-basis-1/2"
            label="Valor"
            min={0}
            placeholder="Valor de la materia prima"
            prefix="$ "
            thousandSeparator=","
            typeInput="number"
            {...formRawMaterial.getInputProps('value')}
            onBlur={() => formRawMaterial.validateField('value')}
          />
        </div>

        <Input
          allowDecimal
          clampBehavior="strict"
          className="cd-basis-[100%] md:cd-basis-1/2"
          label="Alerta de stock"
          placeholder="Cantidad mínima para alerta"
          thousandSeparator=","
          typeInput="number"
          {...formRawMaterial.getInputProps('alertStock')}
          onBlur={() => formRawMaterial.validateField('alertStock')}
        />

        <Fieldset legend="Archivos adjuntos">
          <Input
            clearable
            searchable
            data={buildSelectOptions<RawMaterialBaseType>({
              data: rawMaterialsAttachmentsQuery.data,
              value: 'uid',
              label: (item) =>
                `${item.name} (${item.attachments.length === 1 ? `${item.attachments.length} archivo` : `${item.attachments.length} archivos`})`,
            })}
            filter={(input) =>
              filterSelect({
                limit: 5,
                search: input.search,
                options: input.options,
              })
            }
            nothingFoundMessage={
              <Text c="dimmed" className="cd-text-sm">
                No se encontraron resultados
              </Text>
            }
            placeholder="Escribe para buscar materia prima existente"
            typeInput="select"
            value={selectedReuseRM?.uid ?? ''}
            onChange={(value) => {
              if (!value) {
                formRawMaterial.setFieldValue(
                  'attachments',
                  formRawMaterial.values.attachments.filter(
                    (item) => !selectedReuseRM?.attachments.includes(item),
                  ),
                  { forceUpdate: true },
                )

                setSelectedReuseRM(null)
                return
              }

              const rawMaterial = rawMaterialsAttachmentsQuery.data?.find(
                (item) => item.uid === value,
              )

              if (!rawMaterial) return

              const currentFiles = formRawMaterial.values.attachments ?? []

              const newFiles = [...currentFiles, ...rawMaterial.attachments]

              if (newFiles.length > MAX_ATTACHMENTS) {
                notifications.show({
                  color: 'yellow',
                  title: 'Advertencia',
                  message: `Se ha preservado solo los últimos ${MAX_ATTACHMENTS} archivos adjuntos`,
                })
              }

              formRawMaterial.setFieldValue('attachments', newFiles.slice(0, MAX_ATTACHMENTS), {
                forceUpdate: true,
              })

              setSelectedReuseRM(rawMaterial)
            }}
          />

          <Dropzone
            multiple
            className="cd-mt-[1rem]"
            disabled={isLoading || formRawMaterial.values.attachments.length === 4}
            files={formRawMaterial.values.attachments}
            maxFiles={MAX_ATTACHMENTS}
            onDrop={(files) => {
              if (files.length > MAX_ATTACHMENTS) {
                notifications.show({
                  color: 'yellow',
                  title: 'Advertencia',
                  message: `Se ha preservado solo los últimos ${MAX_ATTACHMENTS} archivos adjuntos`,
                })
              }

              formRawMaterial.setFieldValue('attachments', files.slice(0, MAX_ATTACHMENTS), {
                forceUpdate: true,
              })
            }}
          />
        </Fieldset>

        <Button
          className="cd-mt-[1rem]"
          disabled={!formRawMaterial.isValid() || isLoadingAttachments}
          loaderProps={{ type: 'dots' }}
          loading={isLoading}
          type="submit"
        >
          {data ? 'Guardar materia prima' : 'Crear materia prima'}
        </Button>
      </div>
    </form>
  )

  if (modal) {
    return (
      <Modal
        centered
        fullScreen={isMobile}
        opened={Boolean(open)}
        size={size}
        title={data ? 'Editar materia prima' : 'Agregar materia prima'}
        onClose={() => {
          if (formRawMaterial.isDirty()) {
            setConfirmExit(true)
          } else {
            onClose()
          }
        }}
      >
        {content}
        <ConfirmationModal
          cancelColor="gray"
          cancelText="Cancelar"
          confirmColor="red"
          confirmText="Descartar"
          opened={confirmExit}
          title="Descartar cambios"
          onCancel={() => setConfirmExit(false)}
          onConfirm={onClose}
        >
          <Text className="cd-text-base">
            Has realizado cambios en el formulario, ¿Deseas cerrarlo y descartar los cambios?
          </Text>
        </ConfirmationModal>
      </Modal>
    )
  }

  return content

  function onClose() {
    outerOnClose?.()
    setConfirmExit(false)
    formRawMaterial.resetDirty()
  }
}

export default RawMaterialForm
