import React from 'react'
import { useForm } from '@mantine/form'
import { useListState } from '@mantine/hooks'
import { notifications } from '@mantine/notifications'
import { zodResolver } from 'mantine-form-zod-resolver'
import { IconSearch } from '@tabler/icons-react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Button, Divider, Loader, Modal, Table, Text } from '@mantine/core'

import {
  BlockType,
  BlockBaseType,
  CreateBlockType,
  UpdateBlockType,
  CreateBlockSchema,
  ReceiptBlockType,
} from '@customTypes/block'
import { ModalFormType, ModalProps } from '@customTypes/modal'
import { ReceiptType } from '@customTypes/receipt'

import DataRepo from '@api/datasource/data'
import queryClient from '@api/datasource/query'

//import { UI } from '@constants/app'
import QueryKeys from '@constants/queryKeys'

import { useIsMobile, useTableMinWidth } from '@hooks/mobile'

import { ErrorService } from '@utils/error'
import { getTotal } from '@utils/rawMaterial'
import { buildSelectOptions, filterSelect } from '@utils/form'
import { isLoadingMutation, isLoadingOrRefetchQuery } from '@utils/network'

import Input from '@components/shared/input'
import NumberFormat from '@components/shared/Number'
import ConfirmationModal from '@components/shared/confirmationModal'
import ReceiptForm, { ReceiptFormType } from '@components/receipt/form'
import TableCustom, { GenericColumnType } from '@components/shared/table'

export type BlockFormType = {
  blockIDs: string[]
  block?: BlockBaseType
}

const INITIAL = {
  uid: '',
  name: '',
  description: '',
  descriptionProforma: '',
  receipts: [],
  deleted: false,
}

const BlockForm = (props: ModalProps<BlockFormType, BlockType>) => {
  const { open, modal, data, size = 'lg', onSubmitted, onClose: outerOnClose } = props

  const isMobile = useIsMobile()

  const minWidth = useTableMinWidth(isMobile)

  const [modalForm, setModalForm] = React.useState<ModalFormType<ReceiptFormType>>({
    opened: false,
  })

  const [confirmExit, setConfirmExit] = React.useState(false)

  const [selectedRows, handlers] = useListState<string>([])

  const formBlock = useForm<CreateBlockType>({
    initialValues: INITIAL,
    validate: zodResolver(CreateBlockSchema),
  })

  const receiptsQuery = useQuery<ReceiptType[], ErrorService>({
    initialData: [],
    queryKey: [QueryKeys.GET_RECEIPTS_EXTENDED_KEY],
    queryFn: async () => {
      return await DataRepo.getReceiptsExtended({
        orderDir: 'asc',
      })
    },
  })

  const blockCreateMutation = useMutation<BlockType, ErrorService, CreateBlockType>({
    mutationFn: async (data) => {
      const response = await DataRepo.createBlock(data)

      await queryClient.invalidateQueries({
        predicate: (query) => [QueryKeys.GET_RECEIPTS_KEY].includes(query.queryKey[0] as string),
        refetchType: 'all',
      })

      onSubmitted?.(response)

      return response
    },
    onSettled: (_, error) => {
      if (error) {
        return notifications.show({
          color: 'red',
          title: 'Error',
          message: error.message ?? 'Error al crear la boque',
        })
      }

      onClose()

      setModalForm({ opened: false })
    },
  })

  const blockUpdateMutation = useMutation<void, ErrorService, UpdateBlockType>({
    mutationFn: async (data) => {
      const response = await DataRepo.updateBlock(data)

      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.GET_BLOCK_KEY, data.current.uid],
        refetchType: 'all',
        exact: true,
      })

      await queryClient.invalidateQueries({
        predicate: (query) => [QueryKeys.GET_RECEIPTS_KEY].includes(query.queryKey[0] as string),
        refetchType: 'all',
      })

      onSubmitted?.()

      return response
    },
    onSettled: (_, error) => {
      if (error) {
        return notifications.show({
          color: 'red',
          title: 'Error',
          message: error.message ?? 'Error al actualizar la boque',
        })
      }

      onClose()
    },
  })

  React.useEffect(() => {
    if (open && data?.block) {
      formBlock.setValues(data.block)
    } else if (!open) {
      formBlock.setValues(INITIAL)
      handlers.setState([])
    }
    formBlock.resetDirty()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, data?.block])

  const { receipts } = formBlock.getValues()

  const isLoading = isLoadingMutation(blockCreateMutation, blockUpdateMutation)

  const content = data && (
    <form
      onSubmit={formBlock.onSubmit((values) => {
        if (data.block) {
          blockUpdateMutation.mutate({
            current: values,
            previous: data.block,
          })
        } else {
          blockCreateMutation.mutate(values as CreateBlockType)
        }
      })}
    >
      <div className="cd-flex cd-flex-col cd-gap-y-[1rem]">
        {!data.block && (
          <Input
            uppercase
            label="ID"
            placeholder="Ingrese el ID de la boque"
            typeInput="text"
            {...formBlock.getInputProps('uid')}
            onBlur={() => {
              if (validateID(formBlock.values.uid)) {
                formBlock.validateField('uid')
              }
            }}
          />
        )}

        <Input
          label="Nombre"
          placeholder="Ingrese el nombre de la boque"
          typeInput="text"
          {...formBlock.getInputProps('name')}
          onBlur={() => formBlock.validateField('name')}
        />

        <Input
          autosize
          label="Descripción"
          maxRows={4}
          minRows={2}
          placeholder="Describa la boque"
          typeInput="textarea"
          {...formBlock.getInputProps('description')}
          onBlur={() => formBlock.validateField('description')}
        />

        <Input
          autosize
          label="Descripción de proforma"
          maxRows={6}
          minRows={2}
          placeholder="Describa la receta para la proforma"
          typeInput="textarea"
          {...formBlock.getInputProps('descriptionProforma')}
          onBlur={() => formBlock.validateField('descriptionProforma')}
        />

        <Divider
          className="cd-mt-[1rem]"
          label={
            <Text c="dimmed" className="cd-text-base">
              Recetas
            </Text>
          }
        />

        <Input
          clearable
          searchable
          data={buildSelectOptions<ReceiptType>({
            data: receiptsQuery.data.filter((item) => !receipts.some((rm) => rm.uid === item.uid)),
            value: 'uid',
            label: (item) => `${item.uid} - ${item.name}`,
          })}
          filter={(input) =>
            filterSelect({
              limit: 5,
              search: input.search,
              options: input.options,
            })
          }
          nothingFoundMessage={
            <div>
              <Text c="dimmed" className="cd-text-sm">
                {receiptsQuery.data?.length
                  ? 'No se pueden agregar recetas existentes'
                  : 'No hay recetas existentes'}
              </Text>
              <Button
                className="cd-mt-[1rem]"
                color="blue"
                size="xs"
                variant="light"
                onClick={() =>
                  setModalForm({
                    opened: true,
                    data: {
                      receiptIDs: receiptsQuery.data.map((item) => item.uid),
                    },
                  })
                }
              >
                Crear nueva receta
              </Button>
            </div>
          }
          placeholder="Escribe para buscar receta existente"
          rightSection={
            isLoadingOrRefetchQuery(receiptsQuery) ? <Loader size={18} /> : <IconSearch size={18} />
          }
          typeInput="select"
          onChange={(value) => {
            const receipt = receiptsQuery.data?.find((item) => item.uid === value)

            if (!receipt) return

            const receiptBlock: CreateBlockType['receipts'][0] = {
              quantity: 1,
              uid: receipt.uid,
              name: receipt.name,
              description: receipt.description,
              descriptionProforma: receipt.descriptionProforma,
              rawMaterials: receipt.rawMaterials,
              updatedAt: receipt.updatedAt,
              createdAt: receipt.createdAt,
              deleted: receipt.deleted,
            }

            formBlock.insertListItem('receipts', receiptBlock)

            formBlock.validate()
          }}
        />

        {/* {!selectedRows.length && (
          <div className="cd-flex cd-justify-end">
            <Button
              color="green"
              leftSection={<IconBox size={18} />}
              size={UI.Size}
              variant="filled"
              onClick={() =>
                setModalForm({
                  opened: true,
                  data: {
                    receiptIDs: receiptsQuery.data.map((item) => item.uid),
                  },
                })
              }
            >
              Crear boque
            </Button>
          </div>
        )} */}

        <TableCustom<ReceiptBlockType>
          columns={buildColumns()}
          data={receipts}
          extraRows={
            <Table.Tr className="cd-relative cd-cursor-default" key="extra-row">
              <Table.Td colSpan={3} />
              <Table.Td className="cd-font-bold" colSpan={1}>
                Total
              </Table.Td>
              <Table.Td className="cd-text-right" colSpan={1}>
                <NumberFormat
                  className="cd-font-bold"
                  value={receipts.reduce(
                    (acc, item) =>
                      acc +
                      item.quantity *
                        item.rawMaterials.reduce(
                          (acc, rm) =>
                            acc +
                            getTotal({
                              value: rm.value,
                              quantity: rm.quantity,
                              hasAccessory: false,
                              percentage: 0,
                            }),
                          0,
                        ),
                    0,
                  )}
                />
              </Table.Td>
            </Table.Tr>
          }
          keyId="uid"
          minWidth={minWidth}
          selectedRows={selectedRows}
          onChangeSelected={handlers}
          onDelete={(ids) => {
            const filtered = receipts.filter((item) => !ids.includes(item.uid))

            formBlock.setFieldValue('receipts', filtered, { forceUpdate: true })
            handlers.setState([])
          }}
        />

        <ReceiptForm
          modal
          data={modalForm.data}
          open={modalForm.opened}
          size="xl"
          onClose={() => setModalForm({ opened: false })}
          onSubmitted={(rm) => {
            if (!rm) return

            formBlock.insertListItem('receipts', rm)
          }}
        />

        <Button
          className="cd-mt-[1rem]"
          disabled={!formBlock.isValid()}
          loaderProps={{ type: 'dots' }}
          loading={isLoading}
          type="submit"
        >
          {data.block ? 'Editar boque' : 'Crear boque'}
        </Button>
      </div>
    </form>
  )

  if (modal) {
    return (
      <Modal
        centered
        fullScreen={isMobile}
        opened={Boolean(open)}
        size={size}
        title={data?.block ? 'Editar boque' : 'Crear boque'}
        onClose={() => {
          if (formBlock.isDirty()) {
            setConfirmExit(true)
          } else {
            onClose()
          }
        }}
      >
        {content}
        <ConfirmationModal
          cancelColor="gray"
          cancelText="Cancelar"
          confirmColor="red"
          confirmText="Descartar"
          opened={confirmExit}
          title="Descartar cambios"
          onCancel={() => setConfirmExit(false)}
          onConfirm={onClose}
        >
          <Text className="cd-text-base">
            Has realizado cambios en el formulario, ¿Deseas cerrarlo y descartar los cambios?
          </Text>
        </ConfirmationModal>
      </Modal>
    )
  }

  return content

  function buildColumns() {
    return [
      { key: 'uid', label: 'ID', width: '3%', type: 'text' },
      { key: 'name', label: 'Nombre', width: '5%', type: 'text' },
      {
        key: 'quantity',
        label: 'Cantidad',
        width: '2%',
        type: 'number',
        inputProps: {
          min: 1,
          defaultValue: (item) => item.quantity ?? 1,
          allowNegative: false,
          onChange: handleMaterialInput.bind(null, 'quantity'),
        },
      },
      {
        key: 'calc',
        label: 'Total ($)',
        width: '5%',
        type: 'calc',
        align: 'right',
        defaultOnClick: true,
        render: (item) => (
          <NumberFormat
            value={
              item.quantity *
              item.rawMaterials.reduce(
                (acc, rm) =>
                  acc +
                  getTotal({
                    value: rm.value,
                    quantity: rm.quantity,
                    hasAccessory: false,
                    percentage: 0,
                  }),
                0,
              )
            }
          />
        ),
      },
    ] as GenericColumnType<ReceiptBlockType>[]
  }

  function validateID(value: string) {
    if (data?.blockIDs.includes(value)) {
      formBlock.setFieldError('uid', 'ID ya existe')
      return false
    }

    return true
  }

  function handleMaterialInput(
    key: keyof ReceiptBlockType,
    value: number | string | boolean,
    uid: string,
  ) {
    const rawMaterialIdx = receipts.findIndex((item) => item.uid === uid)

    if (rawMaterialIdx === -1) return

    formBlock.setFieldValue(`receipts.${rawMaterialIdx}.${key}`, value, { forceUpdate: true })
  }

  function onClose() {
    formBlock.reset()
    setConfirmExit(false)
    outerOnClose?.()
  }
}

export default BlockForm
