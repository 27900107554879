import React from 'react'
import { useListState } from '@mantine/hooks'
import { useNavigate } from 'react-router-dom'
import { notifications } from '@mantine/notifications'
import { IconCubePlus, IconEdit } from '@tabler/icons-react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { ActionIcon, Badge, Button, Container, Group, List, Text } from '@mantine/core'

import { ProformaBaseType, GetProformasType } from '@customTypes/proforma'

import DataRepo from '@api/datasource/data'
import queryClient from '@api/datasource/query'

import { useIsMobile, useTableMinWidth } from '@hooks/mobile'

import { DATE_FORMAT, UI } from '@constants/app'
import QueryKeys from '@constants/queryKeys'

import { ErrorService } from '@utils/error'
import { isLoadingMutation, isLoadingOrRefetchQuery } from '@utils/network'
import { onFilterProforma } from '@utils/proforma'

import { useParams } from '@hooks/params'

import ConfirmationModal from '@components/shared/confirmationModal'
import TableCustom, { GenericColumnType } from '@components/shared/table'
import { RoutesApp } from '@constants/routes'
import moment from 'moment'
import { StatusProforma } from '@constants/proforma'

const Proforma = () => {
  const navigate = useNavigate()

  const isMobile = useIsMobile()

  const minWidth = useTableMinWidth(isMobile)

  const [modalDelete, setModalDelete] = React.useState<ProformaBaseType[] | null>(null)

  const [params, setParams] = useParams<GetProformasType>({
    queryKey: QueryKeys.GET_MATERIALS_KEY,
    initialParams: {},
  })

  const [selectedRows, handlers] = useListState<string>([])

  const proformasQuery = useQuery<ProformaBaseType[], ErrorService, ProformaBaseType[]>({
    queryKey: [QueryKeys.GET_MATERIALS_KEY],
    queryFn: async () => {
      const response = await DataRepo.getProformas({})

      return response
    },
  })

  const proformasDeleteMutation = useMutation<void, ErrorService, string[]>({
    mutationFn: async (uids) => {
      const response = await DataRepo.deleteProformas(uids)

      setParams({})

      await queryClient.invalidateQueries({
        predicate: (query) => [QueryKeys.GET_MATERIALS_KEY].includes(query.queryKey[0] as string),
        refetchType: 'all',
      })

      return response
    },
    onSettled: (_, error) => {
      if (error) {
        return notifications.show({
          color: 'red',
          title: 'Error',
          message: error.message ?? 'Error al eliminar la proforma',
        })
      }

      setModalDelete(null)
      handlers.setState([])
    },
  })

  const memoFilterProforma = React.useMemo(
    () =>
      onFilterProforma({
        data: proformasQuery.data,
        params,
      }),
    [proformasQuery.data, params],
  )

  React.useEffect(() => {
    return () => {
      handlers.setState([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isLoading = isLoadingOrRefetchQuery(proformasQuery)

  return (
    <Container className="cd-mt-[1rem] cd-mb-[2rem] md:cd-mt-[2rem] md:cd-pb-[4rem]" size="xl">
      <div className="cd-flex cd-flex-col cd-gap-y-[2rem]">
        <div className="cd-flex cd-flex-col md:cd-flex-row cd-gap-[1rem] cd-grow">
          <Text className="cd-text-lg md:cd-basis-[80%]">Proformas</Text>
          <Button
            fullWidth
            className="md:cd-basis-[20%]"
            color="green"
            leftSection={<IconCubePlus size={18} />}
            size={UI.Size}
            variant="filled"
            onClick={() => {
              navigate(RoutesApp.PROFORMA_FORM.replace('/:uid', ''))
            }}
          >
            Crear
          </Button>
        </div>

        <TableCustom<ProformaBaseType>
          columns={buildColumns()}
          data={memoFilterProforma}
          keyId="uid"
          loading={isLoading}
          loadingMessage="Cargando proformas"
          minWidth={minWidth}
          noDataMessage="No se encontraron proformas"
          placeholderSearch="Buscar proforma"
          selectedRows={selectedRows}
          onChangeSelected={handlers}
          onDelete={(ids) => {
            const data = proformasQuery.data?.filter((item) => ids.includes(item.uid))

            if (!data?.length) return

            setModalDelete(data)
          }}
          onDetail={({ uid }) => {
            navigate(RoutesApp.PROFORMA_VIEW.replace(':uid', uid))
          }}
          onSearch={(query, field, startDate, endDate) =>
            setParams({ query, field, startDate, endDate })
          }
        />
      </div>

      <ConfirmationModal
        cancelColor="gray"
        cancelText="Cancelar"
        confirmColor="red"
        confirmText="Eliminar"
        loading={isLoadingMutation(proformasDeleteMutation)}
        opened={Boolean(modalDelete?.length)}
        title="Eliminar proforma"
        onCancel={() => setModalDelete(null)}
        onConfirm={() => {
          if (!modalDelete) return

          proformasDeleteMutation.mutate(modalDelete.map((item) => item.uid))
        }}
      >
        {modalDelete?.length === 1 && (
          <Text className="cd-text-base">
            ¿Estás seguro que deseas eliminar la proforma{' '}
            <Text inherit className="cd-text-base cd-font-bold" component="span">
              {modalDelete[0].uid} - {modalDelete[0].name}
            </Text>
            ?
          </Text>
        )}
        {modalDelete && modalDelete?.length > 1 && (
          <React.Fragment>
            <Text className="cd-text-base">
              ¿Estás seguro que deseas eliminar las siguientes proformas?
            </Text>
            <List withPadding className="cd-mt-[0.5rem] cd-list-disc" type="unordered">
              {modalDelete.map((item) => (
                <List.Item key={item.uid}>
                  <Text className="cd-text-base">
                    {item.uid} - {item.name}
                  </Text>
                </List.Item>
              ))}
            </List>
          </React.Fragment>
        )}
      </ConfirmationModal>
    </Container>
  )

  function buildColumns() {
    return [
      {
        key: 'sequential',
        label: 'Secuencial',
        width: '10%',
        type: 'text',
        align: 'right',
        searchable: true,
      },
      { key: 'name', label: 'Nombre', width: '30%', type: 'text', searchable: true },
      {
        key: 'status',
        label: 'Estado',
        width: '15%',
        type: 'calc',
        searchable: true,
        defaultOnClick: true,
        render: ({ status }) => (
          <div className="cd-text-center">
            <Badge color={StatusProforma[status].color}>{StatusProforma[status].label}</Badge>
          </div>
        ),
      },
      {
        key: 'client',
        label: 'Cliente',
        width: '20%',
        searchable: true,
        defaultOnClick: true,
        type: 'calc',
        render: ({ client }) => client.name,
      },
      {
        key: 'date',
        label: 'Fecha',
        width: '15%',
        type: 'calc',
        searchable: true,
        defaultOnClick: true,
        render: ({ createdAt }) => moment.unix(createdAt).format(DATE_FORMAT),
      },
      {
        key: 'actions',
        label: 'Acciones',
        width: '5%',
        type: 'calc',
        align: 'center',
        render: ({ uid }) => (
          <Group justify="center">
            <ActionIcon
              variant="transparent"
              onClick={() => {
                navigate(RoutesApp.PROFORMA_FORM.replace(':uid', uid))
              }}
            >
              <IconEdit size={18} />
            </ActionIcon>
          </Group>
        ),
      },
    ] as GenericColumnType<ProformaBaseType>[]
  }
}

export default Proforma
