/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react'
import moment from 'moment'
import { useQuery } from '@tanstack/react-query'
import { Divider, Modal, Table, Text, Title } from '@mantine/core'

import DataRepo from '@api/datasource/data'

import { ModalProps } from '@customTypes/modal'
import { GetRawMaterialsType } from '@customTypes/rawMaterial'
import { ReceiptBaseType, ReceiptType } from '@customTypes/receipt'

import { useParams } from '@hooks/params'
import { useIsMobile, useTableMinWidth } from '@hooks/mobile'

import QueryKeys from '@constants/queryKeys'
import { DATE_FORMAT } from '@constants/app'

import { ErrorService } from '@utils/error'
import { onFilterRawMaterial } from '@utils/rawMaterial'

import NumberFormat from '@components/shared/Number'
import TableCustom, { GenericColumnType } from '@components/shared/table'

import './preview.scss'
import Input from '@components/shared/input'

const RawMaterialPreview = (props: ModalProps<ReceiptBaseType>) => {
  const { open, modal, data, size = 'lg', onClose: outerOnClose } = props

  const isMobile = useIsMobile()

  const minWidth = useTableMinWidth(isMobile)

  const [paramsRawMaterial, setParamsRawMaterial] = useParams<GetRawMaterialsType>({
    queryKey: QueryKeys.GET_MATERIALS_KEY,
    initialParams: {},
  })

  const receiptQuery = useQuery<ReceiptType, ErrorService, ReceiptType, [string, string]>({
    enabled: Boolean(data?.uid),
    queryKey: [QueryKeys.GET_RECEIPT_KEY, String(data?.uid)],
    queryFn: async ({ queryKey }) => {
      const response = await DataRepo.getReceiptById(queryKey[1] as string)

      return response
    },
  })

  const memoFilterRawMaterial = React.useMemo(
    () =>
      onFilterRawMaterial<ReceiptType['rawMaterials'][0]>({
        data: receiptQuery.data?.rawMaterials,
        params: paramsRawMaterial,
      }),
    [paramsRawMaterial, receiptQuery.data?.rawMaterials],
  )

  if (!data) {
    return null
  }

  const { name, description, descriptionProforma, uid, updatedAt } = data

  const content = (
    <div className="cd-flex cd-flex-col cd-gap-y-[1rem]">
      <Title className="cd-text-2xl cd-font-bold">{name}</Title>

      <Divider />

      <div>
        <div className="cd-flex cd-flex-row cd-justify-between">
          <Text c="dimmed" className="cd-text-base">
            {uid}
          </Text>
          <Text c="dimmed" className="cd-text-sm cd-leading-6">
            Actualización: {moment.unix(updatedAt).format(DATE_FORMAT)}
          </Text>
        </div>
        <Text className="cd-text-base">{description}</Text>

        <Input
          autosize
          readOnly
          className="cd-mt-[1rem]"
          typeInput="textarea"
          value={descriptionProforma ?? 'No hay descripción para la proforma'}
          variant="filled"
        />
      </div>

      <Divider label="Materias primas" />

      <TableCustom<ReceiptType['rawMaterials'][0]>
        columns={buildColumns()}
        data={memoFilterRawMaterial}
        extraRows={
          <Table.Tr className="cd-relative cd-cursor-default" key="extra-row">
            <Table.Td colSpan={5} />
            <Table.Td className="cd-font-bold" colSpan={1}>
              Total
            </Table.Td>
            <Table.Td className="cd-text-right" colSpan={1}>
              <NumberFormat
                className="cd-font-bold"
                value={memoFilterRawMaterial?.reduce(
                  (acc, item) => acc + item.quantity * item.value,
                  0,
                )}
              />
            </Table.Td>
          </Table.Tr>
        }
        keyId="uid"
        minWidth={minWidth}
        onSearch={(query, field) => setParamsRawMaterial({ query, field })}
      />
    </div>
  )

  if (modal) {
    return (
      <Modal
        centered
        fullScreen={isMobile}
        opened={Boolean(open)}
        size={size}
        withCloseButton={isMobile}
        onClose={() => outerOnClose?.()}
      >
        {content}
      </Modal>
    )
  }

  return content

  function buildColumns() {
    return [
      { key: 'uid', label: 'ID', width: '5%', type: 'text', searchable: true },
      { key: 'name', label: 'Nombre', width: '10%', type: 'text', searchable: true },
      { key: 'description', label: 'Descripción', width: '20%', type: 'text', searchable: true },
      {
        key: 'value',
        label: 'Valor ($)',
        width: '5%',
        type: 'text',
      },
      {
        key: 'quantity',
        label: 'Cantidad',
        width: '5%',
        type: 'calc',
        defaultOnClick: true,
        render: ({ quantity }) => <NumberFormat decimals={0} prefix="" value={quantity} />,
      },
      { key: 'measure', label: 'Medida', width: '5%', type: 'text', searchable: true },
      {
        key: 'calc',
        label: 'Total ($)',
        width: '5%',
        type: 'calc',
        align: 'right',
        defaultOnClick: true,
        render: (item) => <NumberFormat value={item.quantity * item.value} />,
      },
    ] as GenericColumnType<ReceiptType['rawMaterials'][0]>[]
  }
}

export default RawMaterialPreview
