import React from 'react'
import moment from 'moment'
import { useForm } from '@mantine/form'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { IconPaperclip, IconSearch, IconX } from '@tabler/icons-react'
import { ActionIcon, Button, Container, Group, Text } from '@mantine/core'

import DataRepo from '@api/datasource/data'

import { ModalFormType } from '@customTypes/modal'
import { GeoCheckpointBaseType, GetGeoCheckpointType } from '@customTypes/geoCheckpoint'

import QueryKeys from '@constants/queryKeys'
import { RoutesApp } from '@constants/routes'
import { DATE_FORMAT, UI } from '@constants/app'

import { useParams } from '@hooks/params'
import { useStoreBase } from '@store/index'

import { ErrorService } from '@utils/error'
import { isLoadingOrRefetchQuery } from '@utils/network'
import { getLocationString, onFilterGeoCheckpoint } from '@utils/geoCheckpoint'

import Input from '@components/shared/input'
import GeoPreview from '@components/geoCheckpoint/preview'
import ImagePreview from '@components/shared/imagePreview'
import TableCustom, { GenericColumnType } from '@components/shared/table'
import { useIsMobile, useTableMinWidth } from '@hooks/mobile'

const INITIAL = {
  field: 'message',
  query: '',
}

const GeoCheckpoints = () => {
  const navigate = useNavigate()

  const { user } = useStoreBase()

  const isMobile = useIsMobile()

  const minWidth = useTableMinWidth(isMobile)

  React.useEffect(() => {
    if (!user || user.role !== 'admin') {
      return navigate(RoutesApp.HOME)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const [params, setParams] = useParams<GetGeoCheckpointType>({
    queryKey: QueryKeys.GET_GEO_CHECKPOINTS_KEY,
    initialParams: {},
  })

  const [modalImagePreview, setModalImagePreview] = React.useState<ModalFormType<string[]>>({
    opened: false,
  })

  const [modalGeoPreview, setModalGeoPreview] = React.useState<
    ModalFormType<GeoCheckpointBaseType>
  >({
    opened: false,
  })

  const geoCheckpointQuery = useQuery<
    GeoCheckpointBaseType[],
    ErrorService,
    GeoCheckpointBaseType[]
  >({
    enabled: user?.role === 'admin',
    queryKey: [QueryKeys.GET_GEO_CHECKPOINTS_KEY, '2'],
    queryFn: async () => {
      const response = await DataRepo.getGeoCheckpoints({})

      return response
    },
  })

  const formSearchGeo = useForm<{
    query: string
    field: string
    startDate?: number
    endDate?: number
  }>({
    initialValues: INITIAL,
  })

  const memoFilterGeoCheckpoints = React.useMemo(
    () =>
      onFilterGeoCheckpoint({
        data: geoCheckpointQuery.data,
        params,
      }),
    [geoCheckpointQuery.data, params],
  )

  const isLoading = isLoadingOrRefetchQuery(geoCheckpointQuery)

  const columns = buildColumns()

  return (
    <Container className="cd-mt-[1rem] cd-mb-[2rem] md:cd-mt-[2rem] md:cd-pb-[4rem]" size="xl">
      <div className="cd-flex cd-flex-col cd-gap-y-[2rem]">
        <div className="cd-flex cd-flex-col md:cd-flex-row cd-gap-[1rem] cd-grow">
          <Text className="cd-text-lg md:cd-basis-[80%]">Geo Checkpoints</Text>
        </div>

        <form
          className="cd-flex cd-flex-col md:cd-flex-row cd-gap-[1rem] cd-grow cd-mb-[1rem]"
          onSubmit={formSearchGeo.onSubmit(({ query, field, endDate, startDate }) =>
            setParams({ query, field, endDate, startDate }),
          )}
        >
          <div className="md:cd-basis-[80%] cd-flex cd-flex-col md:cd-flex-row cd-gap-[1rem] cd-grow">
            <Input
              allowDeselect={false}
              className="md:cd-basis-[20%] cd-w-full"
              data={columns
                .filter(({ searchable }) => searchable)
                .map((column) => ({ label: column.label, value: column.key as string }))}
              placeholder="Campo"
              typeInput="select"
              {...formSearchGeo.getInputProps('field')}
              onChange={(value) => {
                formSearchGeo.setValues({
                  query: '',
                  field: value ?? '',
                  endDate: undefined,
                  startDate: undefined,
                })
              }}
            />
            {formSearchGeo.values.field === 'date' && (
              <Input
                className="md:cd-basis-[80%] cd-w-full"
                placeholder="Escoge un rango de fechas"
                rightSection={
                  <IconX
                    size={18}
                    onClick={() => {
                      formSearchGeo.reset()
                      setParams(INITIAL)
                    }}
                  />
                }
                type="range"
                typeInput="dateRangePicker"
                onChange={(value) => {
                  const castValue = value as unknown as [Date, Date]
                  const [startDate, endDate] = castValue

                  formSearchGeo.setFieldValue('startDate', moment(startDate).startOf('day').unix())
                  formSearchGeo.setFieldValue('endDate', moment(endDate).endOf('day').unix())
                }}
              />
            )}
            {formSearchGeo.values.field !== 'date' && (
              <Input
                className="md:cd-basis-[80%] cd-w-full"
                placeholder="Buscar registro"
                rightSection={
                  <IconX
                    size={18}
                    onClick={() => {
                      formSearchGeo.reset()
                      setParams(INITIAL)
                    }}
                  />
                }
                typeInput="text"
                {...formSearchGeo.getInputProps('query')}
              />
            )}
          </div>
          <Button
            fullWidth
            className="md:cd-basis-[20%]"
            color="blue"
            leftSection={<IconSearch size={18} />}
            size={UI.Size}
            type="submit"
            variant="filled"
            onClick={() => {}}
          >
            Buscar
          </Button>
        </form>

        <TableCustom<GeoCheckpointBaseType>
          columns={columns}
          data={memoFilterGeoCheckpoints}
          keyId="uid"
          loading={isLoading}
          loadingMessage="Cargando registros"
          minWidth={minWidth}
          placeholderSearch="Buscar registro"
          onDetail={(item) => {
            setModalGeoPreview({ opened: true, data: item })
          }}
        />

        <GeoPreview
          modal
          data={modalGeoPreview.data}
          open={modalGeoPreview.opened}
          size="xl"
          onClose={() => setModalGeoPreview({ opened: false })}
        />

        <ImagePreview
          modal
          data={modalImagePreview.data}
          open={modalImagePreview.opened}
          size="xl"
          onClose={() => setModalImagePreview({ opened: false })}
        />
      </div>
    </Container>
  )

  function buildColumns() {
    return [
      { key: 'message', label: 'Mensaje', width: '25%', type: 'text', searchable: true },
      {
        key: 'user',
        label: 'Usuario',
        width: '15%',
        type: 'calc',
        searchable: true,
        defaultOnClick: true,
        render: (item) => item.user.displayName ?? item.user.email,
      },
      {
        key: 'location',
        label: 'Ubicación',
        width: '15%',
        type: 'calc',
        defaultOnClick: true,
        render: (item) => getLocationString(item),
      },
      {
        key: 'attachment',
        label: 'Adjunto',
        width: '7%',
        type: 'calc',
        defaultOnClick: true,
        render: ({ attachment }) => (
          <Group justify="center">
            <ActionIcon
              variant="transparent"
              onClick={() => {
                setModalImagePreview({ opened: true, data: [attachment] })
              }}
            >
              <IconPaperclip size={18} />
            </ActionIcon>
          </Group>
        ),
      },
      {
        key: 'date',
        label: 'Fecha',
        width: '10%',
        type: 'calc',
        searchable: true,
        defaultOnClick: true,
        render: (item) => moment.unix(item.createdAt).format(DATE_FORMAT),
      },
    ] as GenericColumnType<GeoCheckpointBaseType>[]
  }
}

export default GeoCheckpoints
