import z from 'zod'

const PasswordSchema = z
  .string()
  .min(6, 'Contraseña debe tener al menos 6 caracteres')
  .max(24, 'Contraseña debe tener como máximo 24 caracteres')

export const UserSchema = z.object({
  uid: z.string(),
  email: z.string().email('Email no válido'),
  displayName: z.string().min(3, 'Nombre debe tener al menos 3 caracteres'),
  deviceID: z.string(),
  role: z.enum(['admin', 'user']),
})

export type UserType = z.infer<typeof UserSchema>

// Registering a new user
export const SignUpSchema = UserSchema.omit({ uid: true, deviceID: true, role: true })
  .extend({
    password: PasswordSchema,
    confirmPassword: z
      .string()
      .min(6, 'Contraseña debe tener al menos 6 caracteres')
      .max(24, 'Contraseña debe tener como máximo 24 caracteres'),
  })
  .superRefine(({ confirmPassword, password }, ctx) => {
    if (confirmPassword !== password) {
      ctx.addIssue({
        code: 'custom',
        message: 'Las contraseñas no coinciden',
        path: ['confirmPassword'],
      })
    }
  })

export type SignUpType = z.infer<typeof SignUpSchema>

// Login a user
export const SignInSchema = UserSchema.omit({
  uid: true,
  role: true,
  deviceID: true,
  displayName: true,
}).extend({
  password: PasswordSchema,
})

export type SignInType = z.infer<typeof SignInSchema>
