import React from 'react'
import { useForm } from '@mantine/form'
import { IconArrowRight } from '@tabler/icons-react'
import { notifications } from '@mantine/notifications'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Button, Fieldset, Modal, Text } from '@mantine/core'

import { MoveRawMaterialsType, WarehouseBaseType, WarehouseType } from '@customTypes/warehouse'

import DataRepo from '@api/datasource/data'
import queryClient from '@api/datasource/query'

import QueryKeys from '@constants/queryKeys'

import { useIsMobile, useTableMinWidth } from '@hooks/mobile'

import { ErrorService } from '@utils/error'
import { buildSelectOptions } from '@utils/form'
import { isLoadingMutation } from '@utils/network'

import Input from '@components/shared/input'
import TableCustom, { GenericColumnType } from '@components/shared/table'
import { ModalProps } from '@customTypes/modal'

type RMItem = WarehouseType['rawMaterials'][0] & { maxQuantity: number }

export type MoveRawMaterialType = {
  warehouseFromID?: string
  rawMaterials?: RMItem[]
}

const MoveRawMaterials = (props: ModalProps<MoveRawMaterialType>) => {
  const { open, modal, data = {}, size = 'lg', onClose, onSubmitted } = props

  const { rawMaterials, warehouseFromID } = data

  const isMobile = useIsMobile()

  const minWidth = useTableMinWidth(isMobile, true)

  const warehousesQuery = useQuery<WarehouseBaseType[], ErrorService, WarehouseBaseType[]>({
    queryKey: [QueryKeys.GET_WAREHOUSES_KEY],
    queryFn: async () => {
      const response = await DataRepo.getWarehouses({})

      return response
    },
    initialData: [],
  })

  const formMoveRawMaterials = useForm<MoveRawMaterialsType>({
    initialValues: {
      warehouseFromID: '',
      warehouseToID: '',
      rawMaterials: [],
    },
  })

  React.useEffect(() => {
    if (open && data) {
      formMoveRawMaterials.setValues({
        rawMaterials:
          data.rawMaterials?.map((rawMaterial) => ({
            rawMaterialID: rawMaterial.uid,
            quantity: 1,
          })) ?? [],
        warehouseFromID: warehouseFromID ?? '',
        warehouseToID: '',
      })
    } else if (!open) {
      formMoveRawMaterials.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, data.warehouseFromID, data.rawMaterials])

  const moveRawMaterialsMutation = useMutation<void, ErrorService, MoveRawMaterialsType>({
    mutationFn: async (data) => {
      await DataRepo.moveRawMaterials(data)

      await queryClient.invalidateQueries({
        predicate: (query) => [QueryKeys.GET_COMMITS_KEY].includes(query.queryKey[0] as string),
        refetchType: 'all',
      })

      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.GET_WAREHOUSES_KEY, data.warehouseFromID],
        refetchType: 'all',
        exact: true,
      })
    },
    onSettled: (_, error) => {
      if (error) {
        return notifications.show({
          color: 'red',
          title: 'Error',
          message: error.message ?? 'Error al mover los materiales',
        })
      }

      onClose?.()

      notifications.show({
        color: 'green',
        title: 'Éxito',
        message: 'Materiales movidos correctamente',
      })

      onSubmitted?.()
    },
  })

  const content = rawMaterials && warehouseFromID && (
    <form
      className="cd-flex cd-flex-col cd-gap-y-[1rem]"
      onSubmit={formMoveRawMaterials.onSubmit((values) => moveRawMaterialsMutation.mutate(values))}
    >
      <Fieldset legend="Bodegas">
        <div className="cd-flex md:cd-flex-row cd-grow md:cd-gap-x-[1rem]">
          <Input
            readOnly
            className="cd-grow"
            data={buildSelectOptions({
              data: warehousesQuery.data,
              label: 'name',
              value: 'uid',
            })}
            label="Origen"
            placeholder="Seleccione la bodega de origen"
            typeInput="select"
            {...formMoveRawMaterials.getInputProps('warehouseFromID')}
          />
          <div className="cd-flex cd-flex-col cd-justify-end">
            <IconArrowRight className="cd-mb-[0.5rem]" size={24} />
          </div>
          <Input
            className="cd-grow"
            data={buildSelectOptions({
              data: warehousesQuery.data.filter((warehouse) => warehouse.uid !== warehouseFromID),
              label: 'name',
              value: 'uid',
            })}
            disabled={warehousesQuery.data.length === 1}
            label="Destino"
            nothingFoundMessage="No hay bodegas disponibles"
            placeholder={
              warehousesQuery.data.length === 1
                ? 'No hay bodegas disponibles'
                : 'Seleccione la bodega de destino'
            }
            typeInput="select"
            {...formMoveRawMaterials.getInputProps('warehouseToID')}
          />
        </div>
      </Fieldset>

      <div className="cd-flex cd-flex-col cd-gap-y-[0.5rem]">
        <Text className="cd-text-sm">Lista de materiales a mover</Text>
        <TableCustom<RMItem>
          columns={buildColumns()}
          data={rawMaterials}
          keyId="uid"
          limitPage={5}
          minWidth={minWidth}
          noDataMessage="No hay materiales disponibles"
        />
      </div>

      <Button
        loaderProps={{ type: 'dots' }}
        loading={isLoadingMutation(moveRawMaterialsMutation)}
        type="submit"
      >
        Mover materiales
      </Button>
    </form>
  )

  if (modal) {
    return (
      <Modal
        centered
        fullScreen={isMobile}
        opened={Boolean(open)}
        size={size}
        title="Mover materiales"
        onClose={() => onClose?.()}
      >
        {content}
      </Modal>
    )
  }

  return content

  function buildColumns() {
    return [
      {
        key: 'uid',
        label: 'ID',
        type: 'text',
        width: '10%',
      },
      {
        key: 'name',
        label: 'Nombre',
        type: 'text',
        width: '30%',
      },
      {
        key: 'quantity',
        label: 'Cantidad',
        type: 'number',
        width: '15%',
        inputProps: {
          min: 1,
          defaultValue: 1,
          maxKey: 'maxQuantity',
          allowNegative: false,
          onChange: handleMaterialInput.bind(null, 'quantity'),
        },
      },
    ] as GenericColumnType<RMItem>[]
  }

  function handleMaterialInput(key: string, value: number | string, uid: string) {
    if (!rawMaterials) return

    const rawMaterialIdx = rawMaterials.findIndex((rawMaterial) => rawMaterial.uid === uid)

    if (rawMaterialIdx === -1) return

    formMoveRawMaterials.setFieldValue(`rawMaterials.${rawMaterialIdx}.${key}`, value, {
      forceUpdate: true,
    })
  }
}

export default MoveRawMaterials
